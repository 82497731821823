import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import 'react-datetime/css/react-datetime.css';
import { createGroupMessage, uploadImage, clearSelectedLineUsers } from 'reducers/line';
import { getOptions } from 'selectors/tagsSelectors';
import DropdownSelector from 'components/DropdownComponent';
import DropdownColumn from './DropdownColumn';
import addTag from './add_tag.png';
import img1 from './1.jpg';
import Muitable from '../../Table/muitable';
import { t } from 'i18n/config';

const propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  createGroupMessage: PropTypes.func,
  clearSelectedLineUsers: PropTypes.func,
  features: PropTypes.shape({
    message: PropTypes.bool,
  }),
  uploadImage: PropTypes.func,
  value: PropTypes.number,
  clientId: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.object),
  tagsOverride: PropTypes.arrayOf(PropTypes.object),
  onOpen: PropTypes.func,
  onUpdate: PropTypes.func,
  title: PropTypes.title,
  pageTitle: PropTypes.pageTitle,
  defaultRules: PropTypes.arrayOf(PropTypes.object),
};

const mapStateToProps = (state, props) => ({
  features: state.auth.features,
  tags: getOptions(state, props),
});

const mapDispatchToProps = {
  createGroupMessage,
  uploadImage,
  clearSelectedLineUsers,
};

const defaultProps = {};
const defaultState = {
  title: '',
  textToSend: '',
  imageURL: '',
  type: t('text'),
};

@connect(mapStateToProps, mapDispatchToProps)
class CreateMessage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpenDropDown: false,
      sendType: 'text',
      lineUserRemoved: {},
      totalLineUsers: [],
      ...defaultState,
      ...this.defaultSelect,
      title: props.title,
      loadingSelectedUsers: false,
      loadingSend: false,
    };
  }

  componentDidMount() {
    this.onFetchData();
  }

  componentWillUnmount() {
    this.onClearData();
  }

  onFetchData = async () => {
    const { clientId } = this.props;
    const { rules } = this.state;
    try {
      this.setState({
        loadingSelectedUsers: true,
      });
      const lineUsers = await this.props.onOpen({ clientId, rules });
      this.setState({
        totalLineUsers: lineUsers,
        lineUserRemoved: {},
        loadingSelectedUsers: false,
      });
    } catch (error) {
      alert(error);
    }
  };

  onClearData = () => {
    this.props.clearSelectedLineUsers();
  };

  onUpdateData = async () => {
    const { clientId } = this.props;
    const { rules } = this.state;
    try {
      this.setState({
        loadingSelectedUsers: true,
      });
      const lineUsers = await this.props.onUpdate({ clientId, rules });
      this.setState({
        totalLineUsers: lineUsers,
        lineUserRemoved: {},
        loadingSelectedUsers: false,
      });
    } catch (error) {
      alert(error);
    }
  };

  onDelete = (index) => {
    const s = this.state.select.filter((e, i) => index !== i);
    this.setState({
      select: s,
    });
  };

  onTextChange = (event) => {
    this.setState({
      textToSend: event.target.value,
    });
  };

  onTypeChange = (type) => {
    this.setState({
      sendType: type,
    });
  };

  onPressSend = async () => {
    let msg = {};
    this.setState({
      loadingSend: true,
    });
    switch (this.state.sendType) {
      case 'text': {
        msg = {
          clientId: this.props.clientId,
          message: [],
          lineIDs: this.getCurrentLineUsers().map(({ id }) => id),
          phones: this.getCurrentLineUsers().map(({ phone }) => phone),
          type: this.state.type,
          title: this.state.title,
          deliveryTime: new Date().getTime(),
        };
        if (this.state.textToSend !== '') {
          msg.message.push({
            type: 'text',
            text: this.state.textToSend,
          });
        }
        if (this.state.imageURL !== '') {
          msg.type = t('textPlusImage');
          msg.message.push({
            type: 'image',
            originalContentUrl: this.state.imageURL,
            previewImageUrl: this.state.imageURL,
          });
        }
        break;
      }

      case 'card':
        msg = {
          message: {
            type: 'image',
            originalContentUrl: 'https://allypin-assets.s3-ap-northeast-1.amazonaws.com/222222.jpeg',
            previewImageUrl: 'https://allypin-assets.s3-ap-northeast-1.amazonaws.com/222222.jpeg',
          },
          lineIDs: ['U30fb0f6e38f7378459a467cac2a2da5e'],
        };
        break;
      default:
        break;
    }
    try {
      if (msg.message.length === 0) {
        alert(t('sendFailureEnterMessage'));
        this.setState({ loadingSend: false });
        return;
      }
      if (msg.lineIDs.length === 0) {
        alert(t('sendFailureNoRecipients'));
        this.setState({ loadingSend: false });
        return;
      }
      await this.props.createGroupMessage(msg);
      console.log('msg = ', msg);
      this.onClearMessageInfo();
      alert(t('sendSuccess'));
    } catch (error) {
      alert(t('sendFailureWithError', { error: error.message }));
    }
    this.setState({ loadingSend: false });
    this.props.toggle({ clientId: this.props.clientId });
  };

  onSelectionChange = (users) => {
    const userToRemovedByID = this.state.totalLineUsers.reduce((s, t) => {
      s[t.id] = true;
      return s;
    }, {});
    users.forEach((u) => {
      delete userToRemovedByID[u.id];
    });

    this.setState({
      lineUserRemoved: userToRemovedByID,
    });
  };

  onClearMessageInfo = () => {
    this.setState(defaultState);
  };

  getCurrentLineUsers = () => this.state.totalLineUsers.filter(({ id }) => !this.state.lineUserRemoved[id]);

  get defaultSelect() {
    const { defaultRules } = this.props;
    if (defaultRules) {
      return {
        select: defaultRules.map((e, i) => i),
        addTagCount: defaultRules.length,
        rules: defaultRules.reduce((s, t, i) => {
          const ns = s;
          ns[i] = {
            title: t,
            value: t.values[0],
          };
          return ns;
        }, {}),
      };
    }
    return {
      select: [],
      addTagCount: 0,
      rules: {},
    };
  }
  getColumns = () => [
    { label: 'Name', name: 'name' },
    { label: 'Phone', name: 'phone' },
  ];

  toggleDropDown = () => {
    this.setState({
      isOpenDropDown: !this.state.isOpenDropDown,
    });
  };

  appendDropdown = () => {
    this.setState({
      select: this.state.select.concat(this.state.addTagCount),
      addTagCount: this.state.addTagCount + 1,
    });
  };
  addAddTagCount = () => {
    this.setState({
      addTagCount: this.state.addTagCount + 1,
    });
  };

  handleUploadImage = async (ev) => {
    ev.preventDefault();
    if (this.uploadInput.files[0] == null) {
      this.setState({
        imageURL: '',
      });
      return;
    }

    console.log('ev', ev.target);
    try {
      const data = new FormData();

      const file = this.uploadInput.files[0];
      console.log('file = ', file);
      if (file.size / (1024 * 1024) > 1) {
        alert(t('fileSizeMustBeLessThanMB', { size: 1 }));
        return;
      }
      if (file.type !== 'image/jpeg') {
        alert(t('fileMustBeJpg'));
        return;
      }
      data.append('file', this.uploadInput.files[0]);
      const url = await this.props.uploadImage({ clientId: this.props.clientId, data });
      console.log('done, url = ', url);
      this.setState({
        imageURL: url,
      });
    } catch (error) {
      const err = await error;
      alert(err.message);
    }
  };
  handleChange = (files) => {
    console.log('files = ', files);
  };

  renderInput = (props) => (
    <input
      key={props.value}
      type="checkbox"
      checked={!this.state.lineUserRemoved[props.value]}
      onChange={(e) => {
        this.setState({
          lineUserRemoved: {
            ...this.state.lineUserRemoved,
            [props.value]: !e.target.checked,
          },
        });
      }}
    />
  );

  renderDialog = () => {
    const { textToSend, sendType } = this.state;
    switch (sendType) {
      case 'text':
        return textToSend;
      case 'card':
        return <img className="line-card" src={img1} alt="" />;
      default:
        return '';
    }
  };

  renderImage = () => <img className="line-card-dialog" src={this.state.imageURL} alt="" />;

  renderMobile = () => (
    <div className="line-mobile-space">
      <div className="line-mobile-screen">
        <div className="line-mobile-nav">
          <div className="line-mobile-nav-text">{t('dentalReminderClock')}</div>
        </div>
        <div className="line-mobile-dialog-container">
          <div className="line-mobile-icon" />
          <div className="line-mobile-dialog">{this.renderDialog()}</div>
        </div>
        {this.state.imageURL !== '' && (
          <div className="line-mobile-dialog-container">
            <div className="line-mobile-icon" />
            <div className="line-mobile-dialog">{this.renderImage()}</div>
          </div>
        )}
      </div>
    </div>
  );

  render() {
    const { pageTitle } = this.props;
    const { select, sendType, totalLineUsers } = this.state;
    return (
      <div className="line-create-message-container">
        <div className="message-container">
          <div className="line-create-title">{pageTitle || t('addBulkMessage')}</div>
          <div className="line-whole">
            <div className="line-space">
              <div className="line-message-title-container">
                <div className="line-message-title">{t('bulkMessageName')}</div>
                <div className="line-message-title-input-container">
                  <input
                    className="line-message-title-input"
                    type="text"
                    onChange={(e) => {
                      this.setState({
                        title: e.target.value,
                      });
                    }}
                    value={this.state.title}
                  />
                </div>
              </div>
              <div className="line-message-title-container">
                <div className="line-message-title">
                  {t('customersMatchingCriteria')} {this.getCurrentLineUsers().length}
                  {t('numberOfPeople')}
                </div>
              </div>
              {select.map((e, i) => (
                <DropdownSelector
                  key={`key-${e}`}
                  classes="line-tags-column"
                  titleOptions={this.props.tags}
                  defaultTitleOption={this.props.tags[0]}
                  defaultValueOption={this.props.tags[0].values[0]}
                  onChange={(t, v) => {
                    const rule = this.state.rules[i];
                    this.setState(
                      {
                        rules: {
                          ...this.state.rules,
                          [i]: { ...rule, title: t, value: v },
                        },
                      },
                      this.onUpdateData
                    );
                  }}
                  onDelete={() => {
                    const { rules } = this.state;
                    delete rules[i];
                    this.setState({ rules }, this.onUpdateData);
                    this.onDelete(i);
                  }}
                />
              ))}
              {this.props.features.message && (
                <button className="line-add-button line-tags-column" onClick={this.appendDropdown}>
                  <img className="line-add-button-image" src={addTag} alt="" />
                </button>
              )}
              <div className="line-message-title-container">
                <div className="line-message-title">{t('sendTime')}</div>
                <DropdownColumn classes="line-timing" type="immediate" values={['immediate']} clickable={false} />
              </div>
              <div className="line-message-title-container">
                <div className="line-message-title">{t('messageContent')}</div>
                <div className="line-message-container">
                  <DropdownColumn
                    classes="line-text"
                    type={sendType}
                    values={['text']}
                    textValue={this.state.textToSend}
                    onTextChange={this.onTextChange}
                    onTypeChange={this.onTypeChange}
                    clickable={false}
                  />
                  <div>
                    <input
                      ref={(ref) => {
                        this.uploadInput = ref;
                      }}
                      type="file"
                      onChange={this.handleUploadImage}
                    />
                    {this.state.imageURL && <img className="line-message-image" src={this.state.imageURL} alt="img" />}
                  </div>
                </div>
              </div>
              <div className="line-create-message-button-container">
                <button
                  className="line-create-message-button"
                  onClick={() => {
                    this.props.toggle({ clientId: this.props.clientId });
                  }}
                >
                  {t('cancel')}
                </button>
                <button
                  disabled={this.state.loadingSend}
                  className="line-create-message-button"
                  onClick={this.onPressSend}
                >
                  {t('confirm')}
                </button>
              </div>
            </div>
            {this.renderMobile()}
          </div>
        </div>
        <div className="user-container">
          <Muitable
            selectEnabled
            data={totalLineUsers.map((e) => ({
              ...e,
              checked: !this.state.lineUserRemoved[e.id],
            }))}
            columns={this.getColumns()}
            onSelectionChange={this.onSelectionChange}
            isLoading={this.state.loadingSelectedUsers}
          />
        </div>
      </div>
    );
  }
}

CreateMessage.propTypes = propTypes;
CreateMessage.defaultProps = defaultProps;

export default CreateMessage;
