import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import TextFieldWithFlexVar from 'components/FlexEditor/contentformat/utilsComponents/TextFieldWithFlexVar';
import Select from '@material-ui/core/Select';
import { uploadImageApi } from 'lib/lineService';
import Swapper from './Swapper';
import UploadArea, { UPLOAD_STATUS } from 'components/Dashboard_v2/UploadArea';
import { marginOptions, sizeOptions, aspectModeOptions } from '../../templates/styledComponents';

import { dashboard_v2 } from 'theme';
import { t } from 'i18n/config';

const { colors } = dashboard_v2;

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: '70%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const Container = styled.div`
  > div {
    width: 85%;
  }
`;

const ImgContainer = styled.div`
  position: relative;
  border: 1px solid ${colors.SHADES_400};
  border-radius: 4px;
  width: 100%;
  height: 200px;
  z-index: 10;
  overflow: hidden;
`;

const PreviewImageWrapper = styled.div`
  display: flex;
  position: relative;
  padding: 8px;
  height: 100%;
  align-items: center;
  justify-content: center;
  i {
    position: absolute;
    top: -2px;
    right: 2px;
    font-size: 24px;
    color: ${colors.SHADES_400};
    cursor: pointer;
  }
  img {
    max-height: 100%;
    max-width: 100%;
    display: block;
    margin: auto;
    object-fit: contain;
  }
`;

const ImageUploader = ({ sizeLimit = 1, data = {}, clientId, handleChange, disabled, type }) => {
  const imageRef = useRef(null);
  const [status, setStatus] = useState(data.url ? UPLOAD_STATUS.DONE : UPLOAD_STATUS.INITIAL);

  const handleFileSelect = async (ev) => {
    ev.preventDefault();
    const file = imageRef.current.files[0];
    if (!file) {
      return;
    }
    if (file.size / (1024 * 1024) > sizeLimit) {
      return alert(t(`fileSizeMustBeLessThan${sizeLimit}MB`));
    }
    if (!['image/png', 'image/jpg', 'image/jpeg'].includes(file.type))
      return alert(t('fileFormatMustBe', { format: 'jpg/png' }));

    try {
      setStatus(UPLOAD_STATUS.UPLOADING);
      const data = new FormData();
      data.append('file', file);
      const { url } = await uploadImageApi({ clientId, data });
      handleChange(url);
    } catch (error) {
      const err = await error;
      alert(err.message);
    } finally {
      setStatus(UPLOAD_STATUS.DONE);
    }
  };

  const handleFileClear = () => {
    imageRef.current.value = '';
    handleChange(null);
    setStatus(UPLOAD_STATUS.INITIAL);
  };

  return (
    <ImgContainer>
      {!disabled && (
        <UploadArea
          innerRef={imageRef}
          status={status}
          type={type}
          hint={t('fileFormatSupport', { format: 'jpg/png', size: sizeLimit })}
          accept="image/png, image/jpg, image/jpeg"
          onFileSelect={handleFileSelect}
        />
      )}
      {data.url && (
        <PreviewImageWrapper>
          {!disabled && <i className="ri-close-circle-fill" onClick={handleFileClear} />}
          <img src={data.url} />
        </PreviewImageWrapper>
      )}
    </ImgContainer>
  );
};

function ImageEditorInFlex({ onChange, path, content, parentPath, index, onRemove, onValidRatio }) {
  const classes = useStyles();
  const handleChange = (e) => {
    onChange({
      path,
      content: {
        ...content,
        [e.target.name]: e.target.value,
      },
      index,
      parentPath,
    });
  };
  const handleImageChange = (imageUrl) => {
    onChange({
      path,
      content: {
        ...content,
        url: imageUrl,
      },
      index,
      parentPath,
    });
    console.log('path:', path);
    console.log('content:', content);
  };
  const handleFlexVarChange = (changes) => {
    onChange({
      path,
      content: {
        ...content,
        ...changes,
      },
      index,
      parentPath,
    });
  };
  return (
    <Container>
      <FormControl className={classes.formControl}>
        <Swapper onRemove={onRemove} />
      </FormControl>
      <FormControl className={classes.formControl}>
        <ImageUploader data={content} handleChange={handleImageChange}></ImageUploader>
      </FormControl>
      <FormControl className={classes.formControl}>
        <InputLabel id="margin">{t('flexEditor.margin')}</InputLabel>
        <Select name="margin" labelId="margin" id="margin-select" value={content.margin} onChange={handleChange}>
          {marginOptions.map((op) => (
            <MenuItem key={op} value={op}>
              {op}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl className={classes.formControl}>
        <InputLabel id="aspectMode">{t('flexEditor.aspectMode')}</InputLabel>
        <Select
          name="aspectMode"
          labelId="aspectMode"
          id="aspectMode-select"
          value={content.aspectMode}
          onChange={handleChange}
        >
          {aspectModeOptions.map((op) => (
            <MenuItem key={op} value={op}>
              {op}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextFieldWithFlexVar
        name="aspectRatio"
        value={content.aspectRatio}
        onChange={handleFlexVarChange}
        title={t('flexEditor.aspectRatio')}
        onValidRatio={onValidRatio}
      />
      <FormControl className={classes.formControl}>
        <InputLabel id="size">{t('flexEditor.size')}</InputLabel>
        <Select name="size" labelId="size" id="size-select" value={content.size} onChange={handleChange}>
          {sizeOptions.map((op) => (
            <MenuItem key={op} value={op}>
              {op}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Container>
  );
}

ImageEditorInFlex.propTypes = {
  path: PropTypes.string,
  content: PropTypes.shape({
    type: PropTypes.string,
    url: PropTypes.string,
    gravity: PropTypes.string,
    margin: PropTypes.string,
    aspectMode: PropTypes.string,
    aspectRatio: PropTypes.string,
    size: PropTypes.string,
    align: PropTypes.string,
  }),
  parentPath: PropTypes.string,
  index: PropTypes.number,
  onChange: PropTypes.func,
};

export default ImageEditorInFlex;
