import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { uploadImageApi } from 'lib/lineService';
import UploadArea, { UPLOAD_STATUS } from 'components/Dashboard_v2/UploadArea';
import { dashboard_v2 } from 'theme';
import { t } from 'i18n/config';

const { colors } = dashboard_v2;

const ImgContainer = styled.div`
  position: relative;
  border: 1px solid ${colors.SHADES_400};
  border-radius: 4px;
  width: 400px;
  height: 200px;
  z-index: 2;
  overflow: hidden;
`;

const PreviewImageWrapper = styled.div`
  position: relative;
  padding: 8px;
  height: 100%;
  i {
    position: absolute;
    top: -2px;
    right: 2px;
    font-size: 24px;
    color: ${colors.SHADES_400};
    cursor: pointer;
  }
  img {
    max-height: 100%;
    max-width: 100%;
    display: block;
    margin: 0 auto;
    object-fit: contain;
  }
`;

const ImageEditor = ({ sizeLimit = 1, data = {}, clientId, handleChange, disabled, type }) => {
  const imageRef = useRef(null);
  const [status, setStatus] = useState(data.previewImageUrl ? UPLOAD_STATUS.DONE : UPLOAD_STATUS.INITIAL);

  const handleFileSelect = async (ev) => {
    ev.preventDefault();
    const file = imageRef.current.files[0];
    if (!file) {
      return;
    }
    if (file.size / (1024 * 1024) > sizeLimit) {
      return alert(t(`fileSizeMustBeLessThan${sizeLimit}MB`));
    }
    if (!['image/png', 'image/jpg', 'image/jpeg'].includes(file.type))
      return alert(t('fileFormatMustBe', { format: 'jpg/png' }));

    try {
      setStatus(UPLOAD_STATUS.UPLOADING);
      const data = new FormData();
      data.append('file', file);
      const { url } = await uploadImageApi({ clientId, data });
      handleChange(url);
    } catch (error) {
      const err = await error;
      alert(err.message);
    } finally {
      setStatus(UPLOAD_STATUS.DONE);
    }
  };

  const handleFileClear = () => {
    imageRef.current.value = '';
    handleChange(null);
    setStatus(UPLOAD_STATUS.INITIAL);
  };

  return (
    <ImgContainer>
      {!disabled && (
        <UploadArea
          innerRef={imageRef}
          status={status}
          type={type}
          hint={t('fileFormatSupport', { format: 'jpg/png', size: sizeLimit })}
          accept="image/png, image/jpg, image/jpeg"
          onFileSelect={handleFileSelect}
        />
      )}
      {data.previewImageUrl && (
        <PreviewImageWrapper>
          {!disabled && <i className="ri-close-circle-fill" onClick={handleFileClear} />}
          <img src={data.previewImageUrl} />
        </PreviewImageWrapper>
      )}
    </ImgContainer>
  );
};
const propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  onLoadFile: PropTypes.func,
  sizeLimit: PropTypes.number,
};

ImageEditor.propTypes = propTypes;
export default ImageEditor;
