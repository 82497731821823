import React, { useState, memo } from 'react';
import styled from 'styled-components';
import nanoid from 'nanoid';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import { dashboard_v2 } from 'theme';
import { arrayMoveImmutable } from 'components/utils/arrayMove';
import Button from 'components/Dashboard_v2/Button';
import Input from 'components/Dashboard_v2/Input';
import Text from 'components/Dashboard_v2/Text';
import Loading from 'components/Dashboard_v2/Loading';
import MorePopover from './MorePopover';
import { t } from 'i18n/config';
import { SYSTEM_CLIENT_ID } from '../index';
import { translateCategoryName } from 'utils/locale';

const { colors } = dashboard_v2;

const Wrapper = styled.div`
  min-width: 320px;
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  text-align: right;
  border-top: solid 1px ${colors.SHADES_300};
  padding: 4px 8px;
`;

const NameEditorWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${({ mode }) => mode === 'add' && `padding: 16px 16px 16px 24px`};
`;

const IconText = styled.div`
  display: flex;
  align-items: center;
  ${({ padding }) => padding && `padding: ${padding};`}
  cursor: pointer;
  i {
    color: ${colors.SHADES_500};
    margin-right: 8px;
  }
`;

const DragIcon = styled.span`
  display: flex;
  align-items: center;
  margin-right: 8px;
  margin-left: 7px;
  font-size: 20px;
  color: ${colors.SHADES_400};
  cursor: move;
  i:first-child {
    margin-right: -14px;
  }
`;

const DragItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 99999;
  font-size: 16px;
  padding: 10px 16px 10px 10px;
  line-height: 1;
  transition: 0.15s;
  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  > i {
    display: none;
    font-size: 18px;
    color: ${colors.SHADES_400};
    cursor: pointer;
  }
  &:hover {
    background-color: ${colors.SHADES_50};
    > i {
      display: block;
    }
  }
  &.dragging {
    background-color: ${colors.SHADES_50};
  }
`;

const DragHandle = sortableHandle(() => (
  <DragIcon>
    <i className="ri-more-2-fill"></i>
    <i className="ri-more-2-fill"></i>
  </DragIcon>
));

const SortableContainer = sortableContainer(({ children }) => {
  return <SortableItems>{children}</SortableItems>;
});

const SortableItems = styled.div`
  display: flex;
  flex-direction: column;
`;

const SortableItem = sortableElement(({ category, onDelete, onNameChange }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [draftName, setDraftName] = useState(category.name);
  const handleEditClick = () => {
    setIsEditing(true);
    setAnchorEl(null);
  };
  const handleChange = () => {
    onNameChange(draftName);
    setIsEditing(false);
  };
  const handleDelete = () => {
    onDelete();
    setAnchorEl(null);
  };
  return (
    <DragItem>
      <div>
        <DragHandle />
        {isEditing ? (
          <NameEditor
            value={draftName}
            onInputChange={(e) => setDraftName(e.target.value)}
            onCancel={() => setIsEditing(false)}
            onSave={handleChange}
          />
        ) : (
          <Text font="Body/16px_Regular" color="SHADES_900">
            {translateCategoryName(category.name)}
          </Text>
        )}
      </div>
      {!isEditing && category.clientId !== SYSTEM_CLIENT_ID && (
        <React.Fragment>
          <i className="ri-more-fill" onClick={(e) => setAnchorEl(e.currentTarget)} />
          <MorePopover anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
            <span onClick={handleEditClick}>{t('edit')}</span>
            <span onClick={handleDelete}>{t('delete')}</span>
          </MorePopover>
        </React.Fragment>
      )}
    </DragItem>
  );
});

const NameEditor = ({ mode, value, onInputChange, onCancel, onSave }) => {
  const NAME_LIMIT = 6;
  return (
    <NameEditorWrapper mode={mode}>
      <Input
        width={140}
        value={value}
        onInputChange={onInputChange}
        error={value.length > NAME_LIMIT}
        helperText={value.length > NAME_LIMIT ? t('characterLimit') : ''}
        required
      />
      <div>
        <Button variant="ghost" color="secondary" onClick={onCancel}>
          {t('cancel')}
        </Button>
        <Button variant="fill" color="secondary" onClick={onSave} disabled={!value}>
          {t('save')}
        </Button>
      </div>
    </NameEditorWrapper>
  );
};

const mapCategoryData = (data, type) => {
  const categories = data.map((category) => ({ ...category, templateType: type }));
  categories.shift(); // remove first category which is `all` type.
  return categories;
};

const CategoryEditor = ({ type, data, onSave, isLoading }) => {
  const [categories, setCategories] = useState(mapCategoryData(data, type));
  const [isAdding, setIsAdding] = useState(false);
  const [newCategory, setNewCategory] = useState('');

  const handleAddCategory = () => {
    setCategories((prev) => [...prev, { id: '', name: newCategory, templateType: type }]);
    setNewCategory('');
    setIsAdding(false);
  };

  const handleNameChange = (value, index) => {
    const newCategories = [...categories];
    newCategories[index].name = value;
    setCategories(() => newCategories);
  };

  const handleDelete = (index) => {
    const newCategories = [...categories];
    newCategories.splice(index, 1);
    setCategories(() => newCategories);
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setCategories((prev) => arrayMoveImmutable(prev, oldIndex, newIndex));
  };

  const handleSave = () => {
    onSave(categories);
  };

  return (
    <Wrapper>
      <SortableContainer onSortEnd={onSortEnd} useDragHandle helperClass="dragging">
        {!isAdding && (
          <IconText onClick={() => setIsAdding(true)} padding="16px 16px 8px 24px">
            <i className="ri-add-line" />
            <Text font="Body/14px_Regular" color="SHADES_500">
              {t('clickToAddCategory')}
            </Text>
          </IconText>
        )}
        {isAdding && (
          <NameEditor
            mode="add"
            value={newCategory}
            onInputChange={(e) => setNewCategory(e.target.value)}
            onCancel={() => setIsAdding(false)}
            onSave={handleAddCategory}
          />
        )}
        {categories.map((category, index) => (
          <SortableItem
            category={category}
            index={index}
            idx={index}
            onNameChange={(value) => handleNameChange(value, index)}
            onDelete={() => handleDelete(index)}
            key={nanoid()}
          />
        ))}
      </SortableContainer>
      <ActionButtons>
        {isLoading ? (
          <Loading />
        ) : (
          <Button variant="ghost" color="primary" onClick={handleSave}>
            {t('save')}
          </Button>
        )}
      </ActionButtons>
    </Wrapper>
  );
};

const propTypes = {};

CategoryEditor.propTypes = propTypes;

export default memo(CategoryEditor);
