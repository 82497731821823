import { t } from 'i18n/config';

export const getLocaleByClientRegion = (region) => {
  switch (region) {
    case 'jp':
      return 'jp';
    case 'zh':
      return 'zh-hant';
    default:
      return 'en';
  }
};

export const translateCategoryName = (categoryName) => {
  // @NOTES: api 範本 category name 會強制給中文，前端需要轉換成對應的語系
  return categoryName === '系統預設' ? t('systemDefault') : categoryName;
};
