import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Text from 'components/Dashboard_v2/Text';
import { dashboard_v2 } from 'theme';
import { getDisplayContent } from '../../utils';

const { colors } = dashboard_v2;

const MessageBubbleWrapper = styled.div`
  padding: 24px;
  margin-bottom: 24px;
  white-space: pre-wrap;
  background: ${colors.SHADES_000};
  border-radius: 20px;
  word-wrap: break-word;
  max-width: 300px;
  min-width: ${({ type }) => (type ? '300px' : '60px')};
  ${({ position }) =>
    position === 'right'
      ? `display: flex; margin-left: auto; width: auto; background-color: #b1ed8b;`
      : `margin-right: auto; `}
  img {
    width: 100%;
  }
  > p:not(:first-child) {
    display: block;
    text-align: center;
  }
`;

const ReplyButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: ${({ layout }) => (layout === 'vertical' ? 'column' : 'row')};
  gap: 16px;
  margin-top: 24px;
`;

const ReplyButton = styled.div`
  padding: 8px 20px;
  border-radius: 8px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  ${({ variant, color }) => {
    switch (variant) {
      case 'filled':
        return `
          color: ${colors.SHADES_000};
          background: ${color};
        `;
      case 'outlined':
        return `
          color: ${color};
          border: 1px solid ${color};
        `;
      case 'text':
        return `
          color: ${color}
        `;
    }
  }}
`;

const MessageBubble = ({ data }) => {
  const { type, textTitle, textContent, showReplyButtons, layout, replyButtons, previewImageUrl, replyContents } = data;

  return (
    <>
      <MessageBubbleWrapper type={type}>
        {type === 'text' && textContent && <div dangerouslySetInnerHTML={{ __html: getDisplayContent(textContent) }} />}
        {type === 'flex' && (
          <>
            <Text font="Heading/H6/Heavy" marginBottom={24}>
              {textTitle}
            </Text>
            <div dangerouslySetInnerHTML={{ __html: getDisplayContent(textContent) }} />
            {showReplyButtons && (
              <ReplyButtonWrapper layout={layout}>
                {replyButtons?.map(
                  (buttonData, index) =>
                    buttonData.buttonText && (
                      <ReplyButton variant={buttonData.variant} color={buttonData.color} key={index}>
                        {buttonData.buttonText}
                      </ReplyButton>
                    )
                )}
              </ReplyButtonWrapper>
            )}
          </>
        )}
        {type === 'image' && previewImageUrl && <img src={previewImageUrl} />}
      </MessageBubbleWrapper>
      {showReplyButtons &&
        replyContents?.map(
          ({ replyType, replyContent }, index) =>
            replyButtons[index]?.buttonText &&
            !['link', 'pdf'].includes(replyType) && (
              <React.Fragment key={index}>
                <MessageBubbleWrapper position="right">{replyButtons[index].buttonText}</MessageBubbleWrapper>
                {![null, 'keyword'].includes(replyType) && replyContent && (
                  <MessageBubbleWrapper>
                    {replyType === 'image' ? <img src={replyContent} /> : replyContent}
                  </MessageBubbleWrapper>
                )}
              </React.Fragment>
            )
        )}
    </>
  );
};

const propTypes = {
  data: PropTypes.shape({
    type: PropTypes.string,
    textContent: PropTypes.string,
    confirmText: PropTypes.string,
    cancelText: PropTypes.string,
    previewImageUrl: PropTypes.string,
  }),
};

MessageBubble.propTypes = propTypes;

export default MessageBubble;
