import { v4 as uuidv4 } from 'uuid';
import { dashboard_v2 } from 'theme';
import { t } from 'i18n/config';

const { colors } = dashboard_v2;

export const EDITOR_VARIABLES = {
  name: t('namePlaceholder'),
  // @TODO: hide for later phase
  // clinicPhone: '{{診所電話}}',
};

export const getMessageData = (type) => {
  switch (type) {
    case 'text':
      return {
        type,
        textContent: '',
      };
    case 'flex':
      return {
        type,
        alertText: '',
        textTitle: '',
        textContent: '',
        layout: 'vertical',
        replyButtons: [getDefaultReplyButtonData()],
      };
    case 'image':
      return {
        type,
        originalContentUrl: null,
        previewImageUrl: null,
      };
    default:
      return null;
  }
};

export const getDefaultReplyButtonData = () => ({
  variant: 'filled', //'filled' | 'outlined' | 'text',
  color: '#46c028',
  buttonText: '',
  showReplyNotify: false,
});

export const getDefaultReplyContentData = () => ({
  replyType: null, // null | 'text' | 'image' | 'pdf' | 'keyword' | 'link',
  replyContent: '',
});

export const getDisplayContent = (content, type) => {
  const variables = Object.values(EDITOR_VARIABLES);
  variables.forEach((variable) => {
    const replace = new RegExp(variable, 'g');
    content = content.replace(
      replace,
      `<span style="color: ${colors.PRIMARY_400}; ${type !== 'editor' ? 'font-weight: 700;' : ''}">${variable}</span>`
    );
  });
  return content;
};

export const conventFormDataToTemplateData = ({ templateType, formData, mode }) => {
  const cloneData = JSON.parse(JSON.stringify(formData));

  if (templateType === 'groupMessage') {
    cloneData.templateFlow = {
      actions: [
        {
          id: cloneData.actionId,
          alias: cloneData.alias,
          message: cloneData.message,
          replies: cloneData.replies,
        },
      ],
    };
    delete cloneData.message;
  }

  cloneData.templateFlow.actions.forEach((action) => {
    action.message.forEach((data, index) => {
      if (data.type === 'text') {
        action.message[index] = conventTextDataToTextMessage(data);
      }
      if (data.type === 'flex') {
        if (data.showReplyButtons) {
          action.replies[index] = action.replies[index].map(({ id: replyId, replyType, replyContent }, replyIndex) =>
            replyType
              ? {
                  id: mode === 'edit' && replyId ? replyId : uuidv4(),
                  label: data.replyButtons[replyIndex].buttonText,
                  message: (() => {
                    switch (replyType) {
                      case 'text':
                        return { type: 'text', text: replyContent };
                      case 'image':
                        return { type: 'image', originalContentUrl: replyContent, previewImageUrl: replyContent };
                      case 'keyword':
                        return { type: 'keyword', keyword: replyContent };
                      case 'pdf':
                      case 'link':
                        return { type: 'uri', uri: encodeURI(replyContent) };
                      default:
                        return null;
                    }
                  })(),
                }
              : null
          );
        } else {
          action.replies[index] = [getDefaultReplyContentData()];
        }
        action.message[index] = conventTextDataToFlexMessage({
          ...data,
          templateType,
          replyData: action.replies[index],
        });
      }
    });
  });

  return cloneData;
};

export const conventTemplateDataToFormData = ({ templateType, data }) => {
  const cloneData = JSON.parse(JSON.stringify(data));

  const getReplyType = (message) => {
    if (message.type === 'uri') {
      if (/\.pdf$/i.test(message.uri)) {
        return 'pdf';
      }
      return 'link';
    }
    return message.type;
  };

  cloneData.templateFlow.actions.forEach((action) => {
    action.replies = action.replies || [];

    action.message.forEach((data, index) => {
      action.replies[index] = action.replies[index] || null;
      if (data.type === 'text') {
        action.message[index] = conventTextMessageToFormData(data);
      }
      if (data.type === 'flex') {
        action.message[index] = conventFlexMessageToFormData(data);
        if (action.message[index].showReplyButtons) {
          action.replies[index] = action.replies[index]
            ? action.replies[index].map((reply) => {
                if (!reply) {
                  return { replyType: null, replyContent: '' };
                }
                const { id, message } = reply;
                const { text, keyword, uri, originalContentUrl } = message;
                return {
                  id: id,
                  replyType: getReplyType(message),
                  replyContent: text || keyword || originalContentUrl || decodeURI(uri),
                };
              })
            : action.message[index].replyButtons.map(() => ({ replyType: null, replyContent: '' }));
        }
      }
    });
  });

  if (templateType === 'groupMessage') {
    cloneData.actionId = cloneData.templateFlow.actions[0].id;
    cloneData.alias = cloneData.templateFlow.actions[0].alias;
    cloneData.message = cloneData.templateFlow.actions[0].message;
    cloneData.replies = cloneData.templateFlow.actions[0].replies;
    delete cloneData.templateFlow;
  }

  return cloneData;
};

const conventVariableToDisplayText = (data) => {
  // convent {{name}} to t('namePlaceholder')
  const variableKeys = Object.keys(EDITOR_VARIABLES);
  const displayTexts = Object.values(EDITOR_VARIABLES);
  variableKeys.forEach((key, index) => {
    const replace = new RegExp(`{{${key}}}`, 'g');
    data = data.replace(replace, displayTexts[index]);
  });
  return data;
};

// convent t('namePlaceholder') to {{name}}
const conventDisplayTextToVariable = (data) => {
  const variableKeys = Object.keys(EDITOR_VARIABLES);
  const displayTexts = Object.values(EDITOR_VARIABLES);
  displayTexts.forEach((text, index) => {
    const replace = new RegExp(text, 'g');
    data = data.replace(replace, `{{${variableKeys[index]}}}`);
  });
  return data;
};

// convent text message data to UI data
const conventTextMessageToFormData = (data) => {
  return {
    type: 'text',
    textContent: conventVariableToDisplayText(data.text),
  };
};

// convent text UI data to text message data
const conventTextDataToTextMessage = ({ textContent }) => ({
  type: 'text',
  text: conventDisplayTextToVariable(textContent),
});

// convent flex message data to UI data
const conventFlexMessageToFormData = (data) => {
  return {
    type: 'flex',
    layout: data.contents?.footer?.layout || 'vertical',
    alertText: data.altText || '',
    textTitle: data.contents.hero.contents[0].text || '',
    textContent: conventVariableToDisplayText(data.contents.body.contents[0].text),
    showReplyButtons: !!data.contents?.footer?.contents?.length > 0,
    replyButtons: data.contents?.footer?.contents?.map((button) => {
      const { type, style, borderColor, color, action, contents } = button;
      const variant = (() => {
        switch (type) {
          case 'box':
            return 'outlined';
          case 'link':
            return 'text';
          default:
            if (style === 'link') {
              return 'text';
            }
            return 'filled';
        }
      })();
      const actionData = type === 'box' ? contents[0].action : action;
      return {
        ...getDefaultReplyButtonData(),
        variant,
        color: borderColor || color || (style === 'link' ? '#006db1' : '#46c028'),
        buttonText: actionData.label || '',
        showReplyNotify: !!actionData.text,
      };
    }) || [getDefaultReplyButtonData()],
  };
};

// convent UI data to flex message data
const conventTextDataToFlexMessage = ({
  layout,
  alertText,
  textTitle,
  textContent,
  showReplyButtons,
  replyButtons,
  templateType,
  replyData,
}) => {
  const footer = showReplyButtons
    ? {
        type: 'box',
        layout,
        spacing: 'md',
        contents: replyButtons.map((button, index) => {
          const { variant, color, buttonText, showReplyNotify } = button;
          const type = variant === 'outlined' ? 'box' : 'button';
          const style = variant === 'text' ? 'link' : 'primary';
          const replyType = replyData[index]?.message.type;
          const action = (() => {
            if (!replyType) {
              return {
                type: 'message',
                label: buttonText,
                text: buttonText,
              };
            }

            if (replyType === 'uri') {
              return {
                type: 'uri',
                label: buttonText,
                uri: replyData[index].message.uri,
              };
            }

            if (replyType === 'keyword') {
              const keyword = replyData[index].message.keyword;
              return {
                type: 'postback',
                label: buttonText,
                data: `action=${keyword}&displayText=${keyword}&event=${
                  templateType === 'groupMessage' ? 'group_message' : 'patient_tracking'
                }`,
                ...(showReplyNotify ? { text: buttonText } : { displayText: buttonText }),
              };
            }

            return {
              type: 'postback',
              label: buttonText,
              data: `action=${templateType === 'groupMessage' ? 'click_group_message' : 'click_patient_tracking'}&id=${
                replyData[index].id
              }`,
              ...(showReplyNotify ? { text: buttonText } : { displayText: buttonText }),
            };
          })();

          if (type === 'button') {
            return {
              type,
              style,
              height: 'sm',
              color,
              action,
            };
          }

          if (type === 'box') {
            return {
              type,
              layout,
              contents: [
                {
                  type: 'button',
                  style: 'link',
                  height: 'sm',
                  color,
                  action,
                },
              ],
              margin: 'md',
              borderWidth: 'normal',
              borderColor: color,
              cornerRadius: 'md',
            };
          }
        }),
      }
    : null;

  return {
    type: 'flex',
    altText: alertText,
    contents: {
      type: 'bubble',
      hero: {
        type: 'box',
        layout: 'vertical',
        contents: [
          {
            size: 'xl',
            text: textTitle,
            type: 'text',
            align: 'start',
            margin: 'xl',
            weight: 'bold',
            wrap: true,
          },
        ],
        paddingAll: '18px',
      },
      body: {
        type: 'box',
        layout: 'baseline',
        contents: [
          {
            text: conventDisplayTextToVariable(textContent),
            type: 'text',
            wrap: true,
            weight: 'regular',
          },
        ],
      },
      ...(footer && { footer }),
    },
  };
};

export const isValidURL = (url) => {
  try {
    const { protocol } = new URL(url);
    console.log({ protocol, url });
    return ['http:', 'https:'].includes(protocol);
  } catch (err) {
    return false;
  }
};
