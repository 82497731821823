import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import { CircularProgress } from '@material-ui/core';
import { get } from 'lodash-es';

import { getTemplateCategories, getTemplate, createTemplate, updateTemplate } from 'lib/messageCenterService';
import connectData from 'components/HOCs/connectData';
import useAlert from 'hooks/useAlert';

import { dashboard_v2 } from 'theme';
import Button from 'components/Dashboard_v2/Button';
import Block from 'components/Dashboard_v2/Block';

import { InfoBlock, ActionBlock } from '../components/EditBlocks';
import CategoryModal from '../components/CategoryModal';
import InfoModal from '../components/InfoModal';
import TimeModal, { getDisplayTime } from '../components/TimeModal';
import MessageModal from '../components/MessageModal/index';
import { conventFormDataToTemplateData, conventTemplateDataToFormData } from '../utils';
import { t } from 'i18n/config';
import { translateCategoryName } from 'utils/locale';

const { colors } = dashboard_v2;

const Container = styled.div`
  position: relative;
  padding-bottom: 150px;
`;

const Header = styled.div`
  padding: 30px 30px 24px;
  margin: 0 -30px;
  background-color: ${colors.SHADES_000};
  border-bottom: 1px solid ${colors.SHADES_200};
  display: flex;
`;

const FlowContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const FormActions = styled.div`
  margin-left: auto;
  display: flex;
  gap: 0 8px;
`;

const Main = styled.div`
  margin: 30px 0;
`;

const IconButton = styled(Button)`
  font-size: 16px;
  line-height: 1.3;

  i {
    font-size: 14px;
  }
`;

const MODE_NAME_MAP = {
  create: {
    title: t('add'),
    saveButton: t('saveAndAdd'),
  },
  edit: {
    title: t('edit'),
    saveButton: t('updateAndClose'),
  },
};

const getDefaultAction = () => ({
  alias: null,
  timeUnit: null,
  timeValue: null,
  hour: null,
  minute: null,
  delaySeconds: null,
  message: [],
  replies: [],
});

const getInitialFormState = (type) => ({
  title: '',
  description: '',
  templateType: type,
  messageTemplateCategoryId: null,
  messageTemplateCategoryName: null,
  templateFlow: {
    actions: [getDefaultAction()],
  },
});

const TemplateForm = ({ mode, type, match }) => {
  const templateId = match.params.id;
  const { renderAlert, setAlert } = useAlert();
  const clientName = useSelector((state) => state.auth.user.companyName);
  const [clientId] = useSelector((state) => state.clients.selectedIds);
  const { openAI: openAIEnabled } = useSelector((state) => state.clients.byId[clientId]?.features);
  const [formData, setFormData] = useState(null);
  const [redirectToList, setRedirectToList] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [dataIndex, setDataIndex] = useState(null);
  const [editModal, setEditModal] = useState(null);

  const disabledSubmit = useMemo(() => {
    if (!formData) {
      return true;
    }
    const { title, templateFlow, recyclable } = formData;
    const { actions } = templateFlow;
    const isEmptyTitle = !title;
    const isEmptyActions = !actions.length;
    const hasEmptyActionContent = actions.some((action) => {
      const { alias, delaySeconds, message, timeUnit } = action;
      const isEmptyAlias = !alias;
      const isEmptyTime = recyclable && !delaySeconds;
      const isEmptyMessage = !message.length;
      return isEmptyAlias || isEmptyTime || isEmptyMessage || !timeUnit;
    });
    return isEmptyTitle || isEmptyActions || hasEmptyActionContent;
  }, [formData]);

  const handleModalOpen = (type, index) => {
    setDataIndex(index);
    setEditModal(type);
  };

  const handleModalClose = () => {
    setDataIndex(null);
    setEditModal(null);
  };

  const handleActionAdd = (index, action) => {
    const position = index === null ? 0 : index + 1;
    const actions = [...formData.templateFlow.actions];
    const newActions = [...actions.slice(0, position), action || getDefaultAction(), ...actions.slice(position)];
    setFormData((prev) => ({
      ...prev,
      templateFlow: {
        ...prev.templateFlow,
        actions: newActions,
      },
    }));
  };

  const handleActionClone = (index) => {
    const cloneAction = JSON.parse(JSON.stringify(formData.templateFlow.actions[index]));
    cloneAction.alias = `${cloneAction.alias} ${t('clone')}`;
    delete cloneAction.id;
    delete cloneAction.clientId;
    delete cloneAction.parentId;
    handleActionAdd(index, cloneAction);
  };

  const handleActionDelete = (index) => {
    const actions = [...formData.templateFlow.actions];
    actions.splice(index, 1);
    setFormData((prev) => ({
      ...prev,
      templateFlow: {
        ...prev.templateFlow,
        actions,
      },
    }));
  };

  const handleCategoryChange = useCallback(
    (categoryData) =>
      setFormData((prev) => ({
        ...prev,
        messageTemplateCategoryId: get(categoryData, 'id'),
        messageTemplateCategoryName: get(categoryData, 'name'),
      })),
    []
  );

  const handleInfoChange = useCallback(
    (infoData) =>
      setFormData((prev) => ({
        ...prev,
        ...infoData,
      })),
    []
  );

  const handleTimeChange = useCallback(
    (timeData) =>
      setFormData((prev) => {
        const newActions = [...prev.templateFlow.actions];
        newActions[dataIndex] = { ...newActions[dataIndex], ...timeData };
        return {
          ...prev,
          templateFlow: {
            ...prev.templateFlow,
            actions: newActions,
          },
        };
      }),
    [dataIndex]
  );

  const handleMessageChange = useCallback(
    (messageData) =>
      setFormData((prev) => {
        const newActions = [...prev.templateFlow.actions];
        newActions[dataIndex] = { ...newActions[dataIndex], ...messageData };
        return {
          ...prev,
          templateFlow: {
            ...prev.templateFlow,
            actions: newActions,
          },
        };
      }),
    [dataIndex]
  );

  const handleSubmit = async () => {
    if (isLoading) return;

    setIsLoading(true);
    try {
      const payload = conventFormDataToTemplateData({ templateType: 'patientTracking', formData, mode });
      if (mode === 'create') {
        await createTemplate({ clientId, payload });
      }
      if (mode === 'edit') {
        await updateTemplate({ clientId, templateId, payload });
      }
      setAlert({ type: 'success', title: t('successAction', { title: MODE_NAME_MAP[mode].title }) });
      setRedirectToList(true);
    } catch (e) {
      const errRes = await e;
      const { error } = errRes;
      setAlert({ type: 'error', title: error || t('failureAction', { title: MODE_NAME_MAP[mode].title }) });
      console.error(errRes);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const categoryData = await getTemplateCategories({ clientId });
        const translatedCategories = categoryData[type].map((category) => {
          return { ...category, name: translateCategoryName(category.name) };
        });
        setCategories(translatedCategories);
      } catch (e) {
        const errRes = await e;
        const { error } = errRes;
        setAlert({ type: 'error', title: t('loadDataFailed', { error: error || error.message }) });
        console.error(errRes);
      }
    };

    getData();

    if (mode === 'create') {
      setFormData(getInitialFormState(type));
    }

    if (mode === 'edit') {
      const getTemplateData = async () => {
        try {
          const data = await getTemplate({ clientId, templateId });
          setFormData(conventTemplateDataToFormData({ templateType: 'patientTracking', data }));
        } catch (e) {
          const errRes = await e;
          const { error } = errRes;
          setAlert({ type: 'error', title: t('loadTemplateFailed', { error: error || error.message }) });
          console.error(errRes);
        }
      };
      getTemplateData();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return redirectToList ? (
    <Redirect push to="/message_center/templates?type=patientTracking" />
  ) : (
    <Container>
      {renderAlert()}
      <Header>
        <Link to="/message_center/templates?type=patientTracking">
          <IconButton color="secondary" variant="outline">
            <i className="ri-arrow-left-line" />
            {t('return')}
          </IconButton>
        </Link>
        <FormActions>
          <Link to="/message_center/templates?type=patientTracking">
            <Button color="secondary" variant="ghost">
              {t('cancel')}
            </Button>
          </Link>
          <Button color="primary" variant="filled" onClick={handleSubmit} disabled={disabledSubmit}>
            {isLoading ? <CircularProgress style={{ color: '#fff' }} size={20} /> : MODE_NAME_MAP[mode].saveButton}
          </Button>
        </FormActions>
      </Header>
      {formData && (
        <Main>
          {/* Base Info */}
          <Block title={t('basicSettingsForPatientTrackingTemplate')}>
            <InfoBlock
              contents={[
                {
                  title: t('category'),
                  value: formData.messageTemplateCategoryName,
                  placeholder: t('exampleDentistry'),
                },
              ]}
              onEditButtonClick={() => handleModalOpen('category')}
            />
            <InfoBlock
              contents={[
                { title: t('itemName'), value: formData.title, placeholder: t('exampleImplantSurgery') },
                {
                  title: t('description'),
                  value: formData.description,
                  placeholder: t('examplePostoperativePrecautions'),
                },
              ]}
              onEditButtonClick={() => handleModalOpen('info')}
            />
          </Block>
          {/* Flow */}
          <Block title={t('sendingProcessOverview')}>
            <FlowContainer>
              {formData.templateFlow.actions.map((action, index) => (
                <ActionBlock
                  time={getDisplayTime(action)}
                  alias={action.alias}
                  onAddButtonClick={() => handleActionAdd(index)}
                  onCloneButtonClick={() => handleActionClone(index)}
                  onDeleteButtonClick={() => handleActionDelete(index)}
                  onEditTimeClick={() => handleModalOpen('time', index)}
                  onEditMessageClick={() => handleModalOpen('message', index)}
                  hideDelete={index === 0}
                  key={index}
                />
              ))}
            </FlowContainer>
          </Block>
        </Main>
      )}
      {/* Edit Modals */}
      {editModal === 'category' && (
        <CategoryModal
          data={{
            selectedCategory: {
              id: formData.messageTemplateCategoryId,
              name: formData.messageTemplateCategoryName,
            },
            categories,
          }}
          onConfirm={handleCategoryChange}
          onClose={handleModalClose}
        />
      )}
      {editModal === 'info' && (
        <InfoModal
          data={{ title: formData.title, description: formData.description }}
          onConfirm={handleInfoChange}
          onClose={handleModalClose}
        />
      )}
      {editModal === 'time' && (
        <TimeModal
          data={formData.templateFlow.actions[dataIndex]}
          recyclable={formData.recyclable}
          onConfirm={handleTimeChange}
          onClose={handleModalClose}
        />
      )}
      {editModal === 'message' && (
        <MessageModal
          data={{
            ...formData.templateFlow.actions[dataIndex],
            currentAlias: formData.templateFlow.actions.map((action, index) =>
              index === dataIndex ? null : action.alias
            ),
          }}
          clientName={clientName}
          clientId={clientId}
          openAIEnabled={openAIEnabled}
          onConfirm={handleMessageChange}
          onClose={handleModalClose}
        />
      )}
    </Container>
  );
};

const propTypes = {
  mode: PropTypes.string,
  type: PropTypes.string,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
};

TemplateForm.propTypes = propTypes;

export default connectData(TemplateForm);
